
import { defineComponent } from "vue";
import ProductComponent from "@/components/ProductComponent.vue";

export default defineComponent({
  name: "products",
  components: {ProductComponent},
  mounted() {},
  methods: {},
});
